import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import PlaceIcon from '@material-ui/icons/Place';
import PhoneIcon from '@material-ui/icons/Phone';
import Header from '../components/header';
import bokhua from '../assets/images/bokhua.jpeg';
import central from '../assets/images/centraluri.jpeg';
import innova from '../assets/images/inova.png';
import jordania from '../assets/images/jordania.jpeg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },
    fl: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        width: '100%',
    },
    textContainer: {
        padding: '30px',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        margin: '5px',

        '& svg': {
            marginRight: '5px',
            color: '#73BADA',
        },
    },
    h3: {
        fontSize: '15px',
        color: '#3194d1',
        lineHeight: '19px',
        margin: '0 0 25px',
    },
    card: {
        display: 'block',
        '-webkit-box-shadow': '0 3px 27px 7px #dff0fa',
        boxShadow: '0 3px 27px 7px #dff0fa',
        backgroundColor: '#fff',
        marginBottom: '30px',
        '-webkit-transition': 'box-shadow .3s ease',
        '-o-transition': 'box-shadow .3s ease',
        transition: 'box-shadow .3s ease',
    },
}));

export default function Contact() {
    const classes = useStyles();

    return (
        <>
            <Header isAuthenticated="true" />
            <div style={{ marginTop: '20px' }} className={classes.textCenter}>
                კითხვების ან ხარვეზების შემთხვევაში გთხოვთ მოგვწეროთ შემდეგ ელ-ფოსტაზე:{' '}
                <b>info@myvivo.ge</b>
            </div>
            <div>
                <h1 className={classes.textCenter}>ჩვენი კლინიკები და მისამართები</h1>
                <Container maxWidth="lg">
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <div className={classes.card}>
                                    <div>
                                        <img
                                            src={bokhua}
                                            alt="Vivo Medical Group"
                                            className={classes.logo}
                                        />
                                    </div>
                                    <div className={classes.textContainer}>
                                        <h3 className={classes.h3}>
                                            ბოხუას სახელობის კარდიოვასკულარული ცენტრი
                                        </h3>

                                        <div className={classes.center}>
                                            <PlaceIcon />
                                            ჩაჩავას 1
                                        </div>

                                        <div className={classes.center}>
                                            <PhoneIcon />
                                            0322 51 70 08
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className={classes.card}>
                                    <img
                                        src={central}
                                        alt="Vivo Medical Group"
                                        className={classes.logo}
                                    />
                                    <div className={classes.textContainer}>
                                        <h3 className={classes.h3}>
                                            თბილისის ცენტრალური საავადმყოფო
                                        </h3>

                                        <div className={classes.center}>
                                            <PlaceIcon />
                                            ჩაჩავას 1
                                        </div>

                                        <div className={classes.center}>
                                            <PhoneIcon />
                                            032 2 10 44 44
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className={classes.card}>
                                    <img
                                        src={innova}
                                        alt="Vivo Medical Group"
                                        className={classes.logo}
                                    />
                                    <div className={classes.textContainer}>
                                        <h3 className={classes.h3}>ინოვა</h3>

                                        <div className={classes.center}>
                                            <PlaceIcon />
                                            ზ.ანჯაფარიძის I შეს. #6 (ყოფილი სანდრო ეულის ქ.)
                                        </div>

                                        <div className={classes.center}>
                                            <PhoneIcon />
                                            032 2 232 232
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className={classes.card}>
                                    <img
                                        src={jordania}
                                        alt="Vivo Medical Group"
                                        className={classes.logo}
                                    />
                                    <div className={classes.textContainer}>
                                        <h3 className={classes.h3}>ჟორდანიას კლინიკა</h3>

                                        <div className={classes.center}>
                                            <PlaceIcon />
                                            დიღომი: ჩაჩავას 1 ვაკე: აბაშიძის 65
                                        </div>

                                        <div className={classes.center}>
                                            <PhoneIcon />
                                            Digomi 0322 99 08 53; Vake 0322 24 32 32
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        </>
    );
}
