import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() => ({
    serviceSearch: {
        position: 'relative',
        maxWidth: '465px',
        width: '100%',
        margin: '15px 13px 0 0',
    },
    iconButton: {
        position: 'absolute',
        top: '20px',
        left: '10px',
    },
    searchInput: {
        margin: '0 !important',
    },
}));

export default function ServiceSearch(props) {
    const classes = useStyles();
    const { search, setSearch } = props;

    return (
        <div className={classes.serviceSearch}>
            <TextField
                className={classes.searchInput}
                variant="outlined"
                margin="normal"
                fullWidth
                label="მომსახურება"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
}
