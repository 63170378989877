import React from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';

import ChangePassword from './change-password';
import { API_URL } from '../common/api';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ChangePasswordModal(props) {
    const { openModal, setOpenModal } = props;
    const classes = useStyles();

    const handleClose = () => {
        setOpenModal(false);
    };

    const submit = ({ password }) => {
        axios
            .post(`${API_URL}/api/user/change-password`, {
                password,
            })
            .then((res) => {
                if (
                    res.response?.status.toString().startsWith('4') ||
                    res.response?.status === 500
                ) {
                    throw new Error(res.response.data.message);
                } else {
                    handleClose();
                }
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper} style={{ width: 400 }}>
                        <Typography style={{ padding: '20px 0' }} component="h1" variant="h5">
                            პაროლის შეცვლა
                        </Typography>
                        <ChangePassword submit={submit} />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
