/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute(props) {
    const { isAuthenticated, component: Component, ...rest } = props;

    if (isAuthenticated) {
        return <Route {...rest} render={() => <Component {...props} />} />;
    }

    return <Redirect to="/" />;
}
