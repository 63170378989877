/* eslint-disable max-len */
import { Box, Button, Container, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import logo from '../assets/images/logo.png';
import Pallete from '../components/pallete';
import { AuthContext } from '../common/auth-context';
import { API_URL } from '../common/api';
import ResetPasswordModal from '../components/reset-password';
import mainPhoto from '../assets/images/logos.png';

yup.addMethod(yup.string, 'integer', function (val) {
    return this.matches(/^\d+$/, `${val} უნდა შედგებოდეს მხოლოდ ციფრებისაგან`);
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        fontSize: '22px',
        color: '#73BADA',
    },
    loginContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-around',
        paddingLeft: '70px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px',
        },
    },
    flexContainer: {
        display: 'flex',
        height: '100vh',
    },
    logo: {
        maxWidth: 150,
    },
    link: {
        textDecoration: 'underline',
        fontSize: '18px',
        letterSpacing: '0',
        color: '#73BADA',
    },
    rightContainer: {
        flex: 1,
        background: "url('https://myvivo.ge/static/media/logos.de47220e.png') no-repeat",
        backgroundSize: 'contain',
        backgroundPositionY: 'center',
        backgroundPositionX: 'right',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    pwContainer: {
        position: 'relative',
    },
    pwReset: {
        position: 'absolute',
        color: '#73BADA',
        left: '100%',
        top: '52%',
        transform: 'translate(-120%, -50%)',
        outline: 'none',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        fontSize: '15px',
        fontWeight: 'bold',
    },
}));

const validationSchema = yup.object().shape({
    identity: yup
        .string()
        .required('მოცემული ველი სავალდებულოა')
        .integer('პირადი ნომერი')
        .test(
            'len',
            'პირადი ნომერი უნდა შედგებოდეს 11 ციფრისგან',
            (val) => val.toString().length === 11,
        ),
    password: yup.string().required('მოცემული ველი სავალდებულოა'),
});

export default function login(props) {
    const [openModal, setOpenModal] = useState(false);
    const { setOpen, openSnackbar } = props;
    const { dispatch } = React.useContext(AuthContext);
    const history = useHistory();

    const classes = useStyles();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    function fetchNewData() {
        axios
            .get(`${API_URL}/api/services/search-update`)
            .then((res) => {
                if (res.data.isFirstRecord && res.data.isUpdated) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                openSnackbar('error', error);
            });
    }

    function checkAuth(data) {
        axios
            .post(`${API_URL}/api/auth/login`, {
                identity: data.identity,
                password: data.password,
            })
            .then((response) => {
                if (
                    response.response?.status.toString().startsWith('4') ||
                    response.response?.status === 500
                ) {
                    throw new Error(response.response.data.message);
                }

                dispatch({
                    type: 'LOGIN',
                    payload: response.data.data,
                });
                setOpen(false);
                fetchNewData();
                history.push('/services?isloading=true');
            })
            .catch((error) => {
                openSnackbar(error.message);
            });
    }

    return (
        <div className={classes.flexContainer}>
            <div className={classes.loginContainer}>
                <Link to="/login" className={classes.link}>
                    <img src={logo} alt="Vivo Medical Group" className={classes.logo} />
                </Link>
                <Container component="main" maxWidth="xs" style={{ margin: 0 }}>
                    <div className={classes.paper}>
                        <Typography className={classes.title} component="h1" variant="h5">
                            კაბინეტში შესვლა
                        </Typography>
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleSubmit((data) => checkAuth(data))}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="identity"
                                label="პირადი ნომერი"
                                name="identity"
                                autoComplete="off"
                                autoFocus
                                inputRef={register}
                            />
                            {errors.identity && <p className="error">{errors?.identity.message}</p>}
                            <div className={classes.pwContainer}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label="პაროლი"
                                    name="password"
                                    autoComplete="off"
                                    type="password"
                                    inputRef={register}
                                />
                                <button
                                    onClick={() => {
                                        setOpenModal(true);
                                    }}
                                    className={classes.pwReset}
                                    type="button"
                                >
                                    შეხსენება
                                </button>
                            </div>
                            {errors.password && <p className="error">{errors?.password.message}</p>}

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                height="50%"
                            >
                                შესვლა
                            </Button>
                        </form>
                    </div>
                </Container>
                <Box display="flex">
                    <Pallete />
                    <Box display="flex" flexDirection="column" pl={2}>
                        <span>პირველად გვეწვიეთ?</span>
                        <Link to="/register" className={classes.link}>
                            რეგისტრაცია
                        </Link>
                    </Box>
                </Box>
            </div>

            <div className={classes.rightContainer}>
                <img src={mainPhoto} alt="logos" style={{ display: 'none' }} />
            </div>

            <ResetPasswordModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                openSnackbar={openSnackbar}
            />
        </div>
    );
}
