/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Notfound from './pages/notfound';
// eslint-disable-next-line import/no-cycle
import Login from './pages/login';
import Register from './pages/register';
import Services from './pages/services';
import PrivateRoute from './pages/privateRoute';
import PublicRoute from './pages/publicRoute';
import Contact from './pages/contact';
import Profile from './pages/profile';
import Preview from './pages/preview';

export default function Routes(props) {
    const { state } = props;

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    {state.isAuthenticated ? <Redirect to="/services" /> : <Redirect to="/login" />}
                </Route>
                <PublicRoute
                    exact
                    path="/login"
                    component={Login}
                    isAuthenticated={state.isAuthenticated}
                    {...props}
                />
                <PublicRoute
                    exact
                    path="/register"
                    component={Register}
                    isAuthenticated={state.isAuthenticated}
                    {...props}
                />
                <PrivateRoute
                    path="/services"
                    component={Services}
                    isAuthenticated={state.isAuthenticated}
                    {...props}
                />
                <PrivateRoute
                    path="/preview/:id"
                    component={Preview}
                    isAuthenticated={state.isAuthenticated}
                    {...props}
                />
                <PrivateRoute
                    path="/contact"
                    component={Contact}
                    isAuthenticated={state.isAuthenticated}
                    {...props}
                />
                <PrivateRoute
                    path="/profile"
                    component={Profile}
                    isAuthenticated={state.isAuthenticated}
                    {...props}
                />
                <Route path="*" render={() => <Notfound {...props} />} />
            </Switch>
        </BrowserRouter>
    );
}
