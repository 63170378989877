import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    institutionSearch: {
        position: 'relative',
        maxWidth: '320px',
        width: '100%',
        margin: '15px 22px 0 0',
    },
    iconButton: {
        position: 'absolute',
        top: '20px',
        left: '10px',
    },
    selectOption: {
        width: '100%',
    },
}));

const locations = [
    'ბოხუას სახელობის კარდიოვასკულარული ცენტრი',
    'თბილისის ცენტრალური საავადმყოფო',
    'ი. ჟორდანიას სახელობის კლინიკა',
    'ჟორდანია ჩიქოვანის კლინიკა',
    'თბილისის დიაგნოსტიკური ცენტრი',
];

export default function InstitutionSearch(props) {
    const classes = useStyles();
    const { location, setLocation } = props;

    return (
        <div className={classes.institutionSearch}>
            <TextField
                select
                label="დაწესებულება"
                className={classes.selectOption}
                variant="outlined"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
            >
                {locations.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
}
