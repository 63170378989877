/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import Routes from './Routes';
import { AuthContext } from './common/auth-context';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#73BADA',
            contrastText: '#fff',
        },
        text: {
            primary: '#717171',
        },
    },
    typography: {
        fontFamily: [
            'bpgweb',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

let initialState = {
    isAuthenticated: false,
    user: null,
    token: null,
};

const setAxiosInterceptor = () => {
    axios.interceptors.request.use(
        (config) => {
            // eslint-disable-next-line no-param-reassign
            config.headers.authorization = `Bearer ${initialState.token}`;
            return config;
        },
        (error) => Promise.reject(error),
    );
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            localStorage.setItem('token', JSON.stringify(action.payload.token));
            initialState = {
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token,
            };
            setAxiosInterceptor();
            return {
                ...state,
                ...initialState,
            };
        case 'LOGOUT':
            localStorage.clear();
            initialState = {
                isAuthenticated: false,
                user: null,
                token: null,
            };
            setAxiosInterceptor();
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
            };
        default:
            return state;
    }
};

if (initialState.token) {
    setAxiosInterceptor(initialState.token);
}

function App() {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const wrapper = React.createRef();

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 401) {
                dispatch({
                    type: 'LOGOUT',
                });
                history.push('/login');
            }
            return error;
        },
    );

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || null);
        const token = JSON.parse(localStorage.getItem('token') || null);

        if (user && token) {
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                    token,
                },
            });
        }
    }, []);

    const openSnackbar = (error) => {
        setOpen(true);
        setErrorMessage(error);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const props = { setOpen, openSnackbar, state };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthContext.Provider
                value={{
                    state,
                    dispatch,
                }}
            >
                <Routes {...props} />
                <div ref={wrapper}>
                    <Snackbar open={open} autoHideDuration={6000} onClose={closeSnackbar}>
                        <Alert onClose={closeSnackbar} severity="error">
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </div>
            </AuthContext.Provider>
        </ThemeProvider>
    );
}

export default App;
