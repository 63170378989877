import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import { Button, TextField } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { API_URL } from '../common/api';

export default function VerifyCode(props) {
    const { mobile, nextStep, prevStep, handleChange, openSnackbar } = props;
    const { register, handleSubmit } = useForm();

    const validateCode = (data) => {
        axios
            .post(`${API_URL}/api/auth/check-code`, {
                code: data.code,
                mobile,
            })
            .then((res) => {
                if (
                    res.response?.status.toString().startsWith('4') ||
                    res.response?.status === 500
                ) {
                    throw new Error(res.response.data.message);
                }
                handleChange({ code: data.code });
                nextStep();
            })
            .catch((error) => {
                openSnackbar(error.message);
            });
    };

    return (
        <form noValidate onSubmit={handleSubmit((data) => validateCode(data))}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="შეიყვანეთ sms კოდი"
                name="code"
                autoComplete="off"
                autoFocus
                inputRef={register}
            />

            <Button type="submit" fullWidth variant="contained" color="primary" height="50%">
                გაგრძელება
            </Button>
            <div style={{ marginTop: '15px' }} onClick={prevStep} aria-hidden="true">
                <ArrowBackIosIcon />
                დაბრუნება
            </div>
        </form>
    );
}
