import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Header from '../components/header';
import Registration from '../components/registration';
import MobileVerification from '../components/mobile-verification';
import CreatePassword from '../components/create-password';

const stateObject = {
    step: 1,
    identity: '',
    mobile: '',
    code: '',
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    h1: {
        color: '#73BADA',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontSize: '18px',
    },
    span: {
        display: 'block',
        textAlign: 'center',
        color: '#A5A5A5',
        fontSize: '12px',
    },
    backIcon: {
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '30px',
        fontSize: '16px',
        color: '#73BADA',
        cursor: 'pointer',
    },
}));

export default function register(props) {
    const { setOpen, openSnackbar } = props;

    const classes = useStyles();
    const [state, setState] = useState(stateObject);

    const nextStep = () => {
        const { step } = state;
        setState((prevState) => ({
            ...prevState,
            step: step + 1,
        }));
        setOpen(false);
    };

    const prevStep = () => {
        const { step } = state;
        setState((prevState) => ({
            ...prevState,
            step: step - 1,
        }));
        setOpen(false);
    };

    const handleChange = (data) => {
        setState((prevState) => ({
            ...prevState,
            ...data,
        }));
    };

    const renderSwitch = (param) => {
        switch (param) {
            case 1:
                return (
                    <Registration
                        classes={classes}
                        nextStep={nextStep}
                        handleChange={handleChange}
                        openSnackbar={openSnackbar}
                    />
                );
            case 2:
                return (
                    <MobileVerification
                        classes={classes}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        handleChange={handleChange}
                        mobile={state.mobile}
                        openSnackbar={openSnackbar}
                    />
                );
            case 3:
                return (
                    <CreatePassword
                        classes={classes}
                        prevStep={prevStep}
                        openSnackbar={openSnackbar}
                        state={state}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Header />

            {renderSwitch(state.step)}
        </>
    );
}
