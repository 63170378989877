import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@material-ui/core';

import { API_URL } from '../common/api';

const validationSchema = yup.object().shape({});

export default function RequestCode(props) {
    const { handleChange, nextStep, openSnackbar } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const submit = ({ mobile }) => {
        axios
            .post(`${API_URL}/api/auth/send-code`, {
                mobile,
                reason: 'RESET',
            })
            .then((res) => {
                if (res.response?.status.toString().startsWith('4')) {
                    throw new Error(res.response.data.message);
                }

                handleChange({ mobile });
                nextStep();
            })
            .catch((error) => {
                openSnackbar(error.message);
            });
    };

    return (
        <form noValidate onSubmit={handleSubmit((data) => submit(data))}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mobile"
                label="მობილური"
                name="mobile"
                autoComplete="off"
                autoFocus
                inputRef={register}
            />
            {errors.mobile && <p className="error">{errors?.mobile.message}</p>}

            <Button type="submit" fullWidth variant="contained" color="primary" height="50%">
                დასრულება
            </Button>
        </form>
    );
}
