import { Button, Container, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import axios from 'axios';
import { API_URL } from '../common/api';

export default function MobileVerification(props) {
    const { classes, handleChange, nextStep, prevStep, mobile, openSnackbar } = props;
    const { register, handleSubmit } = useForm();

    useEffect(() => {
        axios
            .post(`${API_URL}/api/auth/send-code`, {
                mobile,
                reason: 'REGISTRATION',
            })
            .then((res) => {
                if (res.response?.status.toString().startsWith('4')) {
                    throw new Error(res.response.data.message);
                }
            })
            .catch((error) => {
                openSnackbar(error.message);
            });
    }, []);

    const validateCode = (data) => {
        axios
            .post(`${API_URL}/api/auth/check-code`, {
                code: data.code,
                mobile,
            })
            .then((res) => {
                if (
                    res.response?.status.toString().startsWith('4') ||
                    res.response?.status === 500
                ) {
                    throw new Error(res.response.data.message);
                }
                handleChange(data);
                nextStep();
            })
            .catch((error) => {
                openSnackbar(error.message);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography className={classes.h1} component="h1" variant="h5">
                    რეგისტრაცია
                    <span className={classes.span}>
                        კოდი გამოგზავნილია ნომერზე: <b>{mobile}</b>
                    </span>
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit((data) => validateCode(data))}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="code"
                        label="შეიყვანეთ sms კოდი"
                        name="code"
                        autoComplete="off"
                        autoFocus
                        inputRef={register}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        height="50%"
                    >
                        გაგრძელება
                    </Button>
                    <div className={classes.backIcon} onClick={prevStep} aria-hidden="true">
                        <ArrowBackIosIcon />
                        დაბრუნება
                    </div>
                </form>
            </div>
        </Container>
    );
}
