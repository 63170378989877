import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Button from '@material-ui/core/Button';

import Header from '../components/header';
import { API_URL } from '../common/api';

const Preview = () => {
    const { id } = useParams();
    const [, setIframedoc] = useState('');

    const pdfFile = (base64Html) => {
        const base64ToString = Buffer.from(base64Html, 'base64').toString();

        const iframe = document.createElement('iframe');
        iframe.className = 'fullScreen';
        iframe.setAttribute('id', 'pdf-data');
        iframe.setAttribute('scrolling', 'yes');
        document.getElementById('pdf-container').appendChild(iframe);

        setTimeout(() => {
            const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
            iframedoc.body.innerHTML = base64ToString;

            setIframedoc(iframedoc);
        }, 10);
    };

    const getPdfData = (userId) => {
        axios
            .get(`${API_URL}/api/services/pdf/${userId}`)
            .then((response) => {
                pdfFile(response.data);
            })
            .catch(() => {});
    };

    const downloadPdf = () => {
        document.getElementById('pdf-data').contentWindow.print();
    };

    useEffect(() => {
        getPdfData(id);
    }, []);

    return (
        <>
            <Header isAuthenticated="true" />
            <Container maxWidth="lg" id="pdf-container">
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '20px 0' }}
                        onClick={downloadPdf}
                    >
                        გადმოწერა
                    </Button>
                </div>
            </Container>
        </>
    );
};
export default Preview;
