import React from 'react';
import * as yup from 'yup';

import { Button, TextField } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const validationSchema = yup.object().shape({
    password: yup.string().required('მოცემული ველი სავალდებულოა'),
    repeatPassword: yup
        .string()
        .required('მოცემული ველი სავალდებულოა')
        .oneOf([yup.ref('password'), null], 'პაროლები არ ემთხვევა'),
});

export default function ChangePassword(props) {
    const { submit } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    return (
        <form noValidate onSubmit={handleSubmit((data) => submit(data))}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="პაროლი"
                name="password"
                autoComplete="off"
                autoFocus
                type="password"
                inputRef={register}
            />
            {errors.password && <p className="error">{errors?.password.message}</p>}

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="repeatPassword"
                label="გაიმეორეთ პაროლი"
                name="repeatPassword"
                autoComplete="off"
                type="password"
                inputRef={register}
            />
            {errors.repeatPassword && <p className="error">{errors?.repeatPassword.message}</p>}

            <Button type="submit" fullWidth variant="contained" color="primary" height="50%">
                დასრულება
            </Button>
        </form>
    );
}
