/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import RefreshIcon from '@material-ui/icons/Refresh';
import { blue } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';

import axios from 'axios';
import Header from '../../components/header';
import ServiceSearch from './components/service-search';
import InstitutionSearch from './components/institution-search';
import DateSearch from './components/date-search';
import { API_URL } from '../../common/api';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1265px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        margin: '30px 0 0 0',
        flexWrap: 'wrap',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    filters: {
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'space-around',
    },
    table: {
        marginTop: '20px',
    },
    download: {
        textAlign: 'center',
        width: '100%',
        color: 'rgb(115, 186, 218)',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        border: 'none',
        overflow: 'hidden',
        outline: 'none',
    },
}));
const date = new Date();

const initialFilters = {
    search: '',
    location: '',
    date: '',
};

const Services = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(10);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState(initialFilters);
    const debounced = useDebounce(filters, 500);

    const handleDownload = async (id) => {
        try {
            const { data } = await axios.get(`${API_URL}/api/services/pdf/${id}`, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });

            if (data.statusCode === 404) {
                console.error('Error downloading PDF not found');
                return;
            }

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const columns = [
        { field: 'id', headerName: '#' },
        { field: 'name', headerName: 'მომსახურება', width: 400 },
        { field: 'location', headerName: 'დაწესებულება', width: 400 },
        { field: 'date', headerName: 'თარიღი', width: 200 },
        {
            field: 'actions',
            headerName: ' ',
            sortable: false,
            renderCell: ({ row }) => (
                <button
                    type="button"
                    onClick={() => handleDownload(row.uid)}
                    className={classes.download}
                >
                    ნახვა
                </button>
            ),
        },
    ];

    const fetchNewData = (offset, filtersData = filters) => {
        setLoading(true);
        const params = {
            offset,
        };

        if (filtersData.search) {
            params.search = filtersData.search;
        }

        if (filtersData.location) {
            params.location = filtersData.location;
        }

        if (filtersData.date && filtersData.date !== date) {
            params.date = filtersData.date;
        }

        axios
            .get(`${API_URL}/api/services`, {
                params,
            })
            .then((response) => {
                const services = response.data.data.map((x, i) => ({
                    id: i + 1,
                    uid: x.id,
                    name: x.name,
                    location: x.location,
                    date: moment(x.date).format('DD.MM.YYYY'),
                }));

                setData(services);
                setRowCount(response.data.count);

                const query = new URLSearchParams(window.location.search);
                const isLoading = query.get('isloading');
                setLoading(!!isLoading || false);
            })
            .catch(() => {});
    };

    useEffect(() => {
        fetchNewData(0, filters);
    }, [debounced]);

    const changePage = (newPage) => {
        fetchNewData(newPage * 10);
    };

    const clearFilters = () => {
        setFilters(initialFilters);
    };

    return (
        <>
            <Header isAuthenticated="true" />
            <Container maxWidth="lg">
                <div className={classes.root}>
                    <ServiceSearch
                        search={filters.search}
                        setSearch={(search) => {
                            setFilters((prevState) => ({
                                ...prevState,
                                search,
                            }));
                        }}
                    />
                    <InstitutionSearch
                        location={filters.location}
                        setLocation={(location) => {
                            setFilters((prevState) => ({
                                ...prevState,
                                location,
                            }));
                        }}
                    />
                    <DateSearch
                        date={filters.date}
                        setDate={(date) => {
                            setFilters((prevState) => ({
                                ...prevState,
                                date,
                            }));
                        }}
                    />
                    <Box
                        display="flex"
                        style={{ cursor: 'pointer', margin: '15px 0' }}
                        onClick={clearFilters}
                    >
                        <RefreshIcon style={{ color: blue[500] }} />
                        <span style={{ lineHeight: '25px' }}>ფილტრის ჩამოყრა</span>
                    </Box>
                </div>
                <div className={classes.table}>
                    <DataGrid
                        columns={columns}
                        rows={data}
                        pageSize={10}
                        rowCount={rowCount}
                        onPageChange={(newPage) => changePage(newPage)}
                        pagination
                        paginationMode="server"
                        autoHeight
                        autoPageSize
                        loading={loading}
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnResize
                        disableColumnSelector
                        disableColumnMenu
                        disableSelectionOnClick
                    />
                </div>
            </Container>
        </>
    );
};
export default Services;
