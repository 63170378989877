/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';

export default (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState('');
    const firstDebounce = useRef(true);

    useEffect(() => {
        if (value && firstDebounce.current) {
            setDebouncedValue(value);
            firstDebounce.current = false;
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};
