import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        first: {
            width: '7px',
            height: '8px',
            background: '#ADDFE4 0% 0% no-repeat padding-box',
        },
        second: {
            width: '7px',
            height: '10px',
            background: '#C8DD74 0% 0% no-repeat padding-box',
        },
        third: {
            width: '7px',
            height: '9px',
            background: '#FBC0C6 0% 0% no-repeat padding-box',
        },
        fourth: {
            width: '7px',
            height: '9px',
            background: '#B2DDC0 0% 0% no-repeat padding-box',
        },
        fifth: {
            width: '7px',
            height: '9px',
            background: '#73BADA 0% 0% no-repeat padding-box',
        },
    }),
);

export default function Pallete() {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.first} />
            <div className={classes.second} />
            <div className={classes.third} />
            <div className={classes.fourth} />
            <div className={classes.fifth} />
        </div>
    );
}
