import React from 'react';
import ReactDatePicker from 'react-datepicker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    pickerWrapper: {
        position: 'relative',
        margin: '15px 47px 0 0',
    },
    calIcon: {
        position: 'absolute',
        right: '20px',
        top: '12px',
    },
}));

export default function DateSearch(props) {
    const classes = useStyles();
    const { date, setDate } = props;

    return (
        <div className={classes.pickerWrapper}>
            <ReactDatePicker
                dateFormat="dd.MM.yyyy"
                selected={date}
                onChange={(date) => setDate(date)}
                placeholderText="აირჩიეთ თარიღი"
            />
            <CalendarTodayIcon className={classes.calIcon} />
        </div>
    );
}
