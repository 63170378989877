/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import axios from 'axios';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PhoneIcon from '@material-ui/icons/Phone';
import TodayIcon from '@material-ui/icons/Today';
import PersonIcon from '@material-ui/icons/Person';
import Header from '../components/header';
import { API_URL } from '../common/api';

const useStyles = makeStyles(() => ({
    root: {
        width: '600px',
        margin: '0 auto',
    },
}));

export default function Profile(props) {
    const classes = useStyles();
    const { openSnackbar } = props;
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        axios
            .get(`${API_URL}/api/user/info`)
            .then((response) => {
                setUserInfo({ ...response.data });
            })
            .catch((error) => {
                openSnackbar(error.response.data.message);
            });
    }, []);

    return (
        <>
            <Header isAuthenticated="true" />

            <div className={classes.root}>
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                        <ListItemIcon>
                            <AssignmentIndIcon />
                        </ListItemIcon>
                        <ListItemText primary="პირადი ნომერი" secondary={userInfo.personalId} />
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary="ტელეფონი" secondary={userInfo.phone} />
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <TodayIcon />
                        </ListItemIcon>
                        <ListItemText primary="დაბადების თარიღი" secondary={userInfo.birthDay} />
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="სახელი გვარი" secondary={userInfo.name} />
                    </ListItem>
                </List>
            </div>
        </>
    );
}
