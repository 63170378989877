import React from 'react';
import { Button, Container, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { API_URL } from '../common/api';

yup.addMethod(yup.string, 'integer', function (val) {
    return this.matches(/^\d+$/, `${val} უნდა შედგებოდეს მხოლოდ ციფრებისაგან`);
});

const validationSchema = yup.object().shape({
    identity: yup
        .string()
        .required('მოცემული ველი სავალდებულოა')
        .integer('პირადი ნომერი')
        .test(
            'len',
            'პირადი ნომერი უნდა შედგებოდეს 11 ციფრისგან',
            (val) => val.toString().length === 11,
        ),
    mobile: yup
        .string()
        .required('მოცემული ველი სავალდებულოა')
        .integer('მობილურის ნომერი')
        .test(
            'len',
            'მობილურის ნომერი უნდა შედგებოდეს 9 ციფრისგან',
            (val) => val.toString().length === 9,
        ),
});

export default function Registration(props) {
    const { classes, handleChange, nextStep, openSnackbar } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const sendMobileVerification = async (data) => {
        axios
            .post(
                `${API_URL}/api/auth/check-user`,
                {
                    identity: data.identity,
                    mobile: data.mobile,
                },
                {
                    validateStatus(status) {
                        return status < 400;
                    },
                },
            )
            .then((res) => {
                if (res.response?.status.toString().startsWith('4')) {
                    throw new Error(res.response.data.message);
                }
                handleChange(data);
                nextStep();
            })
            .catch((error) => {
                openSnackbar(error.message);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography className={classes.h1} component="h1" variant="h5">
                    რეგისტრაცია
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit((data) => sendMobileVerification(data))}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="identity"
                        label="პირადი ნომერი"
                        name="identity"
                        autoComplete="off"
                        autoFocus
                        inputRef={register}
                    />
                    {errors.identity && <p className="error">{errors?.identity.message}</p>}

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="mobile"
                        label="მობილურის ნომერი"
                        name="mobile"
                        autoComplete="off"
                        inputRef={register}
                    />
                    {errors.mobile && <p className="error">{errors?.mobile.message}</p>}

                    <span className={classes.span}>*მითითებულ ნომერზე მოგივათ ერთჯერადი კოდი</span>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        height="50%"
                    >
                        გაგრძელება
                    </Button>

                    <span className={classes.span}>
                        ვებ გვერდზე რეგისტრაცია შესაძლებელია მხოლოდ ვივო მედიქალ ჯგუფის კლინიკების
                        პაციენტებისთვის - მათთვის, ვინც უკვე მიიღო მომსახურება ჯგუფის წევრ კლინიკაში
                    </span>
                </form>
            </div>
        </Container>
    );
}
