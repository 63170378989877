import React, { useState } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import ChangePassword from './change-password';
import RequestCode from './request-code';
import VerifyCode from './verify-code';
import { API_URL } from '../common/api';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const stateObject = {
    step: 1,
    mobile: '',
    code: '',
};

export default function ResetPasswordModal(props) {
    const { openModal, setOpenModal, openSnackbar } = props;
    const classes = useStyles();
    const [state, setState] = useState(stateObject);

    const handleClose = () => {
        setOpenModal(false);
        setState(stateObject);
    };

    const submit = ({ password }) => {
        axios
            .post(`${API_URL}/api/auth/reset-password`, {
                password,
                mobile: state.mobile,
                code: state.code,
            })
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const prevStep = () => {
        const { step } = state;
        setState((prevState) => ({
            ...prevState,
            step: step - 1,
        }));
    };

    const nextStep = () => {
        const { step } = state;
        setState((prevState) => ({
            ...prevState,
            step: step + 1,
        }));
    };

    const handleChange = (data) => {
        setState((prevState) => ({
            ...prevState,
            ...data,
        }));
    };

    const renderSwitch = (param) => {
        switch (param) {
            case 1:
                return (
                    <RequestCode
                        nextStep={nextStep}
                        handleChange={handleChange}
                        openSnackbar={openSnackbar}
                    />
                );
            case 2:
                return (
                    <VerifyCode
                        nextStep={nextStep}
                        prevStep={prevStep}
                        mobile={state.mobile}
                        handleChange={handleChange}
                        openSnackbar={openSnackbar}
                    />
                );
            case 3:
                return <ChangePassword submit={submit} prevStep={prevStep} />;
            default:
                return null;
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper} style={{ width: 400 }}>
                        {renderSwitch(state.step)}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
