import React, { useState } from 'react';
import { AppBar, Box, createStyles, makeStyles, Toolbar } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import logo from 'assets/images/logo.png';

import Pallete from './pallete';
import { AuthContext } from '../common/auth-context';
import AnimatedModal from './modal';
import ChangePasswordModal from './change-password-modal';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '30px 50px 0',
            borderBottom: '1px solid #73BADA',
            [theme.breakpoints.down('sm')]: {
                padding: '10px 5px',
            },
        },
        appBar: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        toolbar: {
            justifyContent: 'flex-start',
        },
        logo: {
            maxWidth: 100,
        },
        link: {
            textDecoration: 'none',
            fontSize: '18px',
            letterSpacing: '0',
            color: '#535353',
            padding: '20px 0',
            borderBottom: '4px solid transparent',
            '&:hover': {
                color: '#73BADA',
                borderBottom: '4px solid',
            },
        },
        linkActive: {
            color: '#73BADA',
            borderBottom: '4px solid',
        },
        marginleft: {
            marginLeft: '15px',
        },
        logoUrl: {
            marginRight: '35px',
        },
        profile: {
            color: '#73BADA',
            padding: '20px 0',
            fontSize: '18px',
            marginLeft: '10px',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        dropdown: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            border: '1px solid #E8F4F9',
            padding: '15px',
            position: 'absolute',
            zIndex: '999',

            '&:after': {
                position: 'absolute',
                right: '10%',
                transform: 'translateX(-50%)',
                top: '5px',
                width: '0',
                height: '0',
                content: '""',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '10px solid white',
            },

            '& > ul': {
                listStyle: 'none',
                margin: '0',
                padding: '0 20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                height: '80px',
            },

            '& li': {
                cursor: 'pointer',
            },
        },
    }),
);

function Header(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openPasswordChange, setOpenPasswordChange] = useState(false);

    const name = JSON.parse(localStorage.getItem('user'))?.name;

    // eslint-disable-next-line no-unused-vars
    const { state, dispatch } = React.useContext(AuthContext);
    const { isAuthenticated } = props;
    const links = `${classes.link} ${classes.marginleft}`;

    const logOut = () => {
        dispatch({
            type: 'LOGOUT',
        });
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="static" color="transparent" elevation={0}>
                {isAuthenticated && (
                    <Toolbar className={classes.toolbar}>
                        <Link to="/login" className={classes.logoUrl}>
                            <img
                                src="https://myvivo.ge/static/media/logo.a6dc1a26.png"
                                alt="Vivo Medical Group"
                                className={classes.logo}
                            />
                        </Link>
                        <NavLink
                            activeClassName={classes.linkActive}
                            to="/services"
                            className={links}
                        >
                            სერვისები
                        </NavLink>
                        <NavLink
                            activeClassName={classes.linkActive}
                            to="/contact"
                            className={links}
                        >
                            კონტაქტი
                        </NavLink>
                    </Toolbar>
                )}
                {!isAuthenticated && (
                    <>
                        <Link to="/login" className={classes.logoUrl}>
                            <img src={logo} alt="Vivo Medical Group" className={classes.logo} />
                        </Link>
                        <Box display="flex">
                            <Pallete />
                            <Box display="flex" flexDirection="column" pl={2}>
                                <span>გაქვთ ანგარიში?</span>
                                <Link to="/login" className={classes.link}>
                                    შესვლა
                                </Link>
                            </Box>
                        </Box>
                    </>
                )}
                {isAuthenticated && (
                    <Toolbar className={classes.toolbar}>
                        <AccountCircleIcon style={{ color: '#73BADA' }} fontSize="large" />
                        <div>
                            <Button className={classes.profile} onClick={() => setOpen(!open)}>
                                {name}
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                            {open && (
                                <div className={classes.dropdown}>
                                    <ul>
                                        <li
                                            onClick={() => {
                                                setOpenModal(true);
                                                setOpen(false);
                                            }}
                                            aria-hidden="true"
                                        >
                                            მონაცემები
                                        </li>
                                        <li
                                            onClick={() => {
                                                setOpenPasswordChange(true);
                                                setOpen(false);
                                            }}
                                            aria-hidden="true"
                                        >
                                            პაროლის შეცვლა
                                        </li>
                                        <li
                                            onClick={() => {
                                                setOpen(false);
                                                logOut();
                                            }}
                                            aria-hidden="true"
                                        >
                                            გასვლა
                                        </li>
                                    </ul>
                                </div>
                            )}
                            <AnimatedModal openModal={openModal} setOpenModal={setOpenModal} />
                            <ChangePasswordModal
                                openModal={openPasswordChange}
                                setOpenModal={setOpenPasswordChange}
                            />
                        </div>
                    </Toolbar>
                )}
            </AppBar>
        </div>
    );
}

Header.propTypes = {};

export default Header;
