import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import moment from 'moment';
import axios from 'axios';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PhoneIcon from '@material-ui/icons/Phone';
import TodayIcon from '@material-ui/icons/Today';
import PersonIcon from '@material-ui/icons/Person';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { API_URL } from '../common/api';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    icon: {
        color: '#73BADA',
    },
}));

export default function AnimatedModal(props) {
    const { openModal, setOpenModal } = props;
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        axios.get(`${API_URL}/api/user/info`).then((response) => {
            setUserInfo({ ...response.data });
        });
    }, []);

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItem button>
                                <ListItemIcon className={classes.icon}>
                                    <AssignmentIndIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="პირადი ნომერი"
                                    secondary={userInfo.personalId}
                                />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon className={classes.icon}>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText primary="ტელეფონი" secondary={userInfo.phone} />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon className={classes.icon}>
                                    <TodayIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="დაბადების თარიღი"
                                    secondary={moment(userInfo.birthDay).format('DD.MM.YYYY')}
                                />
                            </ListItem>
                            <ListItem button>
                                <ListItemIcon className={classes.icon}>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="სახელი გვარი" secondary={userInfo.name} />
                            </ListItem>
                        </List>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
