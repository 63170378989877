import { Button, Container, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import axios from 'axios';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../common/api';

const validationSchema = yup.object().shape({
    password: yup.string().required('მოცემული ველი სავალდებულოა'),
    repeatPassword: yup
        .string()
        .required('მოცემული ველი სავალდებულოა')
        .oneOf([yup.ref('password'), null], 'პაროლები არ ემთხვევა'),
    terms: yup.boolean().oneOf([true], 'გთხოვთ დაეთანხმოთ წესებს და პირობებს'),
});

export default function CreatePassword(props) {
    const { classes, prevStep, openSnackbar, state } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const history = useHistory();

    const submit = ({ password }) => {
        const data = { ...state };
        delete data.step;
        delete data.code;
        axios
            .post(`${API_URL}/api/auth/create`, {
                ...data,
                password,
            })
            .then((res) => {
                if (
                    res.response?.status.toString().startsWith('4') ||
                    res.response?.status === 500
                ) {
                    throw new Error(res.response.data.message);
                }
                history.push('/');
            })
            .catch((error) => {
                openSnackbar(error.message);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography className={classes.h1} component="h1" variant="h5">
                    რეგისტრაცია
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit((data) => submit(data))}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="პაროლი"
                        name="password"
                        autoComplete="off"
                        autoFocus
                        type="password"
                        inputRef={register}
                    />
                    {errors.password && <p className="error">{errors?.password.message}</p>}

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="repeatPassword"
                        label="გაიმეორეთ პაროლი"
                        name="repeatPassword"
                        autoComplete="off"
                        type="password"
                        inputRef={register}
                    />
                    {errors.repeatPassword && (
                        <p className="error">{errors?.repeatPassword.message}</p>
                    )}

                    <FormControlLabel
                        control={<Checkbox id="terms" name="terms" inputRef={register} />}
                        label={
                            <div>
                                <span>ვეთანხმები მომსახურების </span>
                                <a href={`${API_URL}/api/terms`} target="_blank" rel="noreferrer">
                                    წესებს და პირობებს
                                </a>
                            </div>
                        }
                        style={{ marginTop: '20px' }}
                    />

                    {errors.terms && <p className="error">{errors?.terms.message}</p>}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        height="50%"
                    >
                        დასრულება
                    </Button>
                    <div className={classes.backIcon} onClick={prevStep} aria-hidden="true">
                        <ArrowBackIosIcon />
                        დაბრუნება
                    </div>
                </form>
            </div>
        </Container>
    );
}
